import _get from "lodash/get"
import { store } from "@/store"
import {
  removeFromCartLineItem,
  setCart,
  setCartCount,
  setShipping,
  shipmentCart,
  shipmentUpdate,
  recentlyViewed,
  setNoshipping,
  addToCart,
  getCart,
  setQtyLoading,
} from "@/store/features/cartSlice"
import { getProfile } from "@/store/features/checkoutSlice"
import { apim } from "@/constants/api"
import {
  getShippingDiscountPromoCodes,
  arrayMove,
  recalculateCartRoomInfo,
} from "@/utils/helper"
import { deleteFromCart, getStock } from "@/utils/cart"

import { removeFromCart, setRemoveCartPayload } from "@/utils/removeCart"
import { getQuantityCount } from "@/js/helperV2"
import { shippingCostEndPoint } from "@/constants/index"
import { showToast } from "@/store/features/genericSlice"

import { addToCartItem } from "@/utils/addTocart"

export const setShippingAddress = async (data, cb, needAnalytics, errorCb) => {
  const { user = {} } = store.getState()?.auth
  if (data.postalCode && data.postalCode.trim() !== "") {
    const address = {
      postalCode: data.postalCode?.trim(),
      state: data.state?.trim(),
      country: data.country?.trim(),
    }
    if (!data?.email) {
      data.email = user?.sub
    }
    const {
      firstName,
      lastName,
      streetName,
      streetNumber,
      city,
      email,
      phone,
    } = data
    if (firstName) address.firstName = firstName.trim()
    if (lastName) address.lastName = lastName.trim()
    if (streetName) address.streetName = streetName.trim()
    if (streetNumber) address.streetNumber = streetNumber.trim()
    if (city) address.city = city.trim()
    if (email) address.email = email.trim().toLowerCase()
    if (phone) address.phone = phone.trim()
    const actions = [
      {
        action: "setShippingAddress",
        address: address,
      },
    ]
    if (email)
      actions.push({
        action: "setCustomerEmail",
        email: email.trim().toLowerCase(),
      })
    if (user?.koc) {
      actions.push({
        action: "setCustomType",
        type: { key: "order", typeId: "type" },
        fields: { CSRAssociateId: user?.koc, orderType: "ISC" },
      })
    }
    const payload = {
      actions: actions,
    }
    await store
      .dispatch(shipmentUpdate(payload))
      .unwrap()
      .then(res => {
        if (res?.type === "Cart" && res.lineItems.length > 0) {
          store.dispatch(setCart(res))
          const quantityLineItems = res?.lineItems
          const payload = getQuantityCount(quantityLineItems)
          store.dispatch(setCartCount(payload))
          getShippingMethods()
          cb && cb(address.postalCode, "success")
          needAnalytics && needAnalytics("success")
        } else {
          if (!phone) {
            cb && cb(address.postalCode, "failure", "no items to ship in cart")
          }
        }
      })
      .catch(error => {
        errorCb && errorCb(error)
        if (!phone) {
          cb &&
            cb(address.postalCode, "failure", "failed to set shipping address.")
        }
      })
  }
}

export const getShippingMethods = async () => {
  const cart = store?.getState()?.cart?.cart
  const lineItemsLength = cart?.lineItems ? cart.lineItems.length : 0
  // const taxedPrice = getState().cart?.cart?.data?.taxedPrice;
  const shippingZip = localStorage.getItem("shippingZip")
  const {
    taxedPrice = "",
    shippingAddress: { postalCode: shippingAddressPostCode = "" } = {},
  } = cart
  let shippingCost = 0
  shippingCost = Number((cart?.shippingTotal?.centAmount ?? 0) / 100).toFixed(2)
  let promoCodes = []
  if (cart?.customLineItems?.length > 0) {
    promoCodes = promoCodes.concat(await getShippingDiscountPromoCodes(cart))
    promoCodes
      .filter(item => item?.type === "shipping")
      .forEach(item => {
        shippingCost = Number(shippingCost) + Number(item.discount)
      })
  }
  if (
    lineItemsLength > 0 &&
    shippingZip &&
    shippingZip.trim() !== "" &&
    shippingAddressPostCode &&
    (!taxedPrice || !shippingCost || (shippingCost && shippingCost * 1 === 0))
  ) {
    await store
      .dispatch(shipmentCart())
      .unwrap()
      .then(response => {
        if (response && response["shipping-details"]) {
          updateProductDetailToStore(response)
        } else {
          getShippingDetails()
            .then(response => {
              updateProductDetailToStore(response)
            })
            .catch(error => {
              console.error(error)
            })
        }
      })
  }
}

export const getProfileData = async (errorMsg = "") => {
  let profile = {}
  try {
    const res = await store.dispatch(getProfile())
    if (res && res.data) {
      localStorage.setItem("profile-version", res.data?.version)
      profile = res.data
      profile.postalCode = profile?.addresses[0]?.postalCode
      const profileAddr = profile?.addresses?.find(
        addr => addr.custom?.fields?.label === "Profile"
      )
      profile.profileAddrId = profileAddr?.id
      profile.addresses = profile?.addresses?.filter(
        addr => addr.custom?.fields?.label !== "Profile"
      )
      let addresses = profile?.addresses
      if (profile?.defaultShippingAddressId) {
        const index = addresses.findIndex(
          item => item.id === profile.defaultShippingAddressId
        )
        addresses = arrayMove(profile?.addresses, index, 0)
      }
      profile.addresses = addresses
    }
  } catch (err) {
    errorMsg &&
      store.dispatch(
        showToast({
          message: errorMsg,
          isVisible: true,
        })
      )
  }
  return profile
}

export const removeCartItem = async (data, cb = null) => {
  const {
    id = "",
    quantity = 0,
    isComboSku = false,
    isBundleParent = false,
    sku,
  } = data

  let payload
  const { shippingAddress: { postalCode = "" } = {} } =
    store?.getState()?.cart?.cart

  if (!Array.isArray(data)) {
    const payloadData = {
      lineItemId: id,
      quantity,
      isBundle: isComboSku,
      isBundleParent,
      sku,
    }
    payload = removeFromCart(payloadData)
  } else {
    payload = setRemoveCartPayload(data)
  }
  payload = payload.reduce((acc, ini) => {
    if (!acc.filter(item => item?.lineItemId === ini?.lineItemId).length) {
      acc.push(ini)
    }
    return acc
  }, [])
  const body = {
    actions: payload,
  }

  await store
    .dispatch(removeFromCartLineItem(body))
    .unwrap()
    .then(res => {
      store.dispatch(setCart(res))
      const quantityLineItems = res?.lineItems
      const payload = getQuantityCount(quantityLineItems)
      store.dispatch(setCartCount(payload))
      if (res.lineItems.length > 0) {
        if (postalCode) {
          getShippingMethods()
        }
      } else {
        deleteFromCart(cb)
      }
    })
}

export const getProductsById = async ids => {
  let query = ""
  ids.forEach((id, i) => {
    query += `"${id}"`
    if (i + 1 !== ids.length) query += ", "
  })
  try {
    const response = await store
      .dispatch(recentlyViewed(query))
      .unwrap()
      .then(res => {
        if (res?.results) {
          return res.results
        }
      })
    if (response) return response
  } catch (err) {
    console.error("Failed to fetch product details", err)
  }
  return []
}

export const getShippingDetails = async () => {
  return new Promise(async (resolve, reject) => {
    const cart = store?.getState()?.cart?.cart
    const shippingDetails = {
      "shipping-details": { results: [] },
      shippingCost: true,
    }
    const response = await apim.get(
      `${shippingCostEndPoint}?cartId=${cart?.id}`
    )
    shippingDetails["shipping-details"]["results"] = response?.data?.results

    if (response) {
      resolve(shippingDetails)
    } else {
      reject(new Error("Cannot ship to your location"))
    }
  })
}

export const updateProductDetailToStore = response => {
  const validProducts =
    response["shipping-details"]?.results?.filter(
      item => !item.warningMessage
    ) ?? []
  const inValidProducts =
    response["shipping-details"]?.results?.filter(
      item => item.warningMessage
    ) ?? []

  store.dispatch(setShipping(validProducts))
  store.dispatch(setNoshipping(inValidProducts))
}

export const getMessageStatus = info => {
  const {
    data,
    email,
    hideAlerts,
    i18n,
    checkoutMode,
    isMobile,
    alert = {},
  } = info

  if (data.cancelled || email || !hideAlerts) {
    if (isMobile) {
      if (alert.show) {
        return true
      } else if (!alert.show && data.outOfStock) {
        return true
      } else if (!alert.show && !data.outOfStock && data.noShipping) {
        return true
      } else if (
        data.category !== "Install Services" &&
        data.category !== "Design Services"
      ) {
        if (
          data.backOrder &&
          data.leadTime &&
          data.quantity > data.availableQty
        ) {
          return true
        } else if (!alert.show) {
          if (data.outOfStock || data.noShipping) {
            return false
          } else {
            return true
          }
        } else {
          return false
        }
      } else return false
    } else {
      return true
    }
  } else if (
    !data.cancelled &&
    hideAlerts &&
    data.backOrder &&
    data.leadTime &&
    data.quantity > data.availableQty &&
    i18n.backOrderMssg
  ) {
    return true
  } else if (!checkoutMode && !isMobile) {
    return true
  } else {
    return false
  }
}

const handleAddtoCartResponse = ({ payload }) => {
  if (payload) {
    store.dispatch(setCart(payload))
  }
  store.dispatch(setQtyLoading(false))
}

export const handleQuantityChange = async (data = {}) => {
  const {
    id = "",
    sku = "",
    quantity = 0,
    isComboSku = false,
    isBundleParent = false,
    stockCheck,
    cb,
  } = data

  let additionalInfo = {}
  const { cart: { qtyLoading = false, cart: { lineItems = [] } = {} } = {} } =
    store?.getState() ?? {}

  const cartItem =
    lineItems.find(item => {
      const {
        custom: { fields: { isBundleChildItem = false } = {} } = {},
        variant: { sku: variantSku = "" } = {},
      } = item

      if (isComboSku) {
        if (!isBundleChildItem && variantSku === sku) return item
      } else if (!isBundleChildItem && variantSku === sku) {
        return item
      }
    }) ?? {}

  const {
    custom: {
      variant: { attributes = [] } = {},
      fields: { roomInfo = "", isBackOrderable: backorder = false } = {},
    } = {},
    quantity: cartQuantity = 0,
    id: lineItemId = "",
  } = cartItem

  const brand = _get(
    attributes?.find(attr => attr.name === "CustomerFacingBrand"),
    "value",
    ""
  )

  if (roomInfo) {
    const roomInfoObj = JSON.parse(roomInfo["0"])

    const { productType = "", soldByBox, soldByUom } = roomInfoObj

    const isTrim = productType === "trim"
    const totalPiecesNo = isTrim ? quantity : soldByBox * quantity
    const totalOverage =
      soldByUom > 0 && totalPiecesNo > 0 ? totalPiecesNo / soldByUom : 0
    const overageVal = Number(cartItem?.custom?.fields?.overage)
    const overagePercent = overageVal > 0 ? overageVal / 100 : 0
    const totalNeeded = Math.floor(totalOverage) / (1 + overagePercent)

    additionalInfo = {
      ...roomInfoObj,
      productType: productType,
      totalNeeded: totalNeeded.toFixed(2),
      totalArea: totalOverage.toFixed(2),
      soldByBox: Number(soldByBox),
      soldByUom: Number(soldByUom),
      customQuantity: quantity,
      overage: overageVal.toString(),
      roomInfo: true,
    }
  }

  if (quantity > cartQuantity && !stockCheck) {
    if (Object.keys(additionalInfo).length > 0) {
      recalculateCartRoomInfo(lineItemId, additionalInfo)
      return
    } else if (cartItem?.custom?.fields?.customQuantity) {
      recalculateCartRoomInfo(lineItemId, { quantity })
      return
    } else {
      const payload = await addToCartItem([
        {
          lineItemId: lineItemId,
          sku,
          quantity: quantity - cartQuantity,
          isBundle: isComboSku,
          backorder,
          brand: brand?.toLowerCase(),
        },
      ])

      const body = {
        actions: payload,
      }

      store
        .dispatch(addToCart(body))
        .unwrap()
        .then(res => {
          handleAddtoCartResponse(res)
          getShippingMethods()
        })

      return
    }
  }

  // change quantity for bundle
  if (isBundleParent) {
    getStock(sku)
      .then(async stock => {
        const isAvailableQty = quantity <= stock
        const payload = await addToCartItem([
          {
            lineItemId: id,
            isBundleParent,
            sku,
            quantity: isAvailableQty ? quantity : stock,
          },
        ])
        const body = {
          actions: payload,
        }

        store
          .dispatch(addToCart(body))
          .unwrap()
          .then(res => {
            handleAddtoCartResponse(res)
            getShippingMethods()
          })
        if (!isAvailableQty) {
          cb(stock)
        }
        store.dispatch(setQtyLoading(qtyLoading))
      })
      .catch(() => {
        store.dispatch(setQtyLoading(qtyLoading))
      })
  }

  // If quantity is increased
  else if (quantity > cartQuantity) {
    store.dispatch(setQtyLoading(!qtyLoading))
    // Checking stock
    getStock(sku)
      .then(async stock => {
        // At first time whenever changes made with input feild
        if (Object.keys(additionalInfo).length > 0) {
          if (quantity <= stock) {
            recalculateCartRoomInfo(lineItemId, additionalInfo, setQtyLoading)
            return
          } else if (quantity > stock) {
            store.dispatch(setQtyLoading(false))
            cb(stock)
            return
          }
        } else if (cartItem?.custom?.fields?.customQuantity) {
          recalculateCartRoomInfo(lineItemId, { quantity }, setQtyLoading)
          return
        }
        if (quantity > stock) {
          if (stock > cartQuantity) {
            const payload = await addToCartItem([
              {
                lineItemId: lineItemId,
                sku,
                quantity: stock - cartQuantity,
                isBundle: isComboSku,
                backorder,
                brand: brand?.toLowerCase(),
              },
            ])

            const body = {
              actions: payload,
            }
            store
              .dispatch(addToCart(body))
              .unwrap()
              .then(res => {
                cb(stock)
                handleAddtoCartResponse(res)
                getShippingMethods()
              })
            cb(stock)
          } else if (stock === cartQuantity) {
            cb(stock)
            store.dispatch(getCart())
          } else if (stock > 0) {
            const payloadData = {
              lineItemId: lineItemId,
              quantity: cartQuantity - stock,
              isBundle: isComboSku,
              sku,
            }

            const payload = removeFromCart(payloadData)
            const body = {
              actions: payload,
            }
            store
              .dispatch(addToCart(body))
              .unwrap()
              .then(res => {
                handleAddtoCartResponse(res)
                getShippingMethods()
              })
          } else {
            cb()
          }
        } else if (cartQuantity !== stock) {
          const payload = await addToCartItem([
            {
              lineItemId: lineItemId,
              sku,
              quantity: quantity - cartQuantity,
              isBundle: isComboSku,
              backorder,
              brand: brand?.toLowerCase(),
            },
          ])
          const body = {
            actions: payload,
          }
          store
            .dispatch(addToCart(body))
            .unwrap()
            .then(res => {
              handleAddtoCartResponse(res)
              getShippingMethods()
            })
        }
        store.dispatch(setQtyLoading(qtyLoading))
      })
      .catch(() => {
        store.dispatch(setQtyLoading(qtyLoading))
      })
  }

  // If quantity is decreased
  else if (cartQuantity > quantity) {
    store.dispatch(setQtyLoading(!qtyLoading))

    if (Object.keys(additionalInfo).length > 0) {
      recalculateCartRoomInfo(lineItemId, additionalInfo, setQtyLoading)
      return
    } else if (cartItem?.custom?.fields?.customQuantity) {
      recalculateCartRoomInfo(lineItemId, { quantity }, setQtyLoading)
      return
    }
    const payloadData = {
      lineItemId: lineItemId,
      quantity: cartQuantity - quantity,
      isBundle: isComboSku,
      sku,
    }

    const payload = removeFromCart(payloadData, true)
    const body = {
      actions: payload,
    }
    store
      .dispatch(addToCart(body))
      .unwrap()
      .then(res => {
        handleAddtoCartResponse(res)
        getShippingMethods()
      })
  }
}
