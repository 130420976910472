/**
 * It add to cart Utility for pay load
 * @returns the addToCartItem
 */

import { getConfig } from "@/constants/config"
export const addToCartItem = async items => {
  let userZipCode
  const CONFIG = await getConfig()
  const salesChannels = CONFIG?.salesChannelConfig?.map ?? {}

  const actionPayload = actionProduct(items, salesChannels, userZipCode)
  return actionPayload
}
/**
 *  payload to add to cart.
 * @param {items} items The first param.
 * @param {salesChannels} salesChannels The second param.
 * @param {userZipCode} userZipCode The third param.
 * @return {object} the pay load for add to cart
 */
const actionProduct = (items, salesChannels, userZipCode) => {
  const actions = []
  let actionData
  items.forEach(product => {
    const {
      lineItemId,
      isBundle = false,
      isBundleProduct = false,
      isBundleParent = false,
      sku = "",
      quantity = 0,
      additionalInfo,
      brand = "",
    } = product
    const {
      roomInfo = "",
      customQuantity = 0,
      overage = "",
    } = additionalInfo ?? {}

    const fields = { salesChannel: salesChannels[product.brand] ?? "KOUS" }
    if (product.backorder) fields.isBackOrderable = true
    const bundlePayload = {
      action: "changeBundleItemQuantity",
      customLineItemId: lineItemId,
      sku,
      quantity,
    }
    const payload = {
      action: isBundleProduct
        ? "addBundleItem"
        : isBundle
        ? "addbundle"
        : "addLineItem",
      sku,
      quantity,
      custom: additionalInfo
        ? {
            type: { key: "lineItemShipping" },
            fields: {
              salesChannel: salesChannels[brand],
              roomInfo,
              customQuantity,
              overage,
            },
          }
        : {
            type: { key: "lineItemShipping" },
            fields: fields,
          },
    }
    actions.push(isBundleParent ? bundlePayload : payload)
    userZipCode = product?.userZipCode
    actionData = [...actions]
  })
  return actionData
}
