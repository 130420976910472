import { Component } from "react"
/**
 * Class declaration for handling frontend errors
 */
class ErrorBoundary extends Component {
  /**
   *
   * @param {*} props
   */
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }
  /**
   *
   * @param {*} error
   * @return {boolean}
   */
  static getDerivedStateFromError(error) {
    return { hasError: true }
  }
  /**
   *
   * @param {*} error
   * @param {*} errorInfo
   */
  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    console.log({ error, errorInfo })
  }
  /**
   *
   * @return {JSX.Element}
   */
  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h2>Oops, there is an error!</h2>
          <button
            type="button"
            aria-label="Oops, there is an error!, try again?"
            onClick={() => this.setState({ hasError: false })}
          >
            Try again?
          </button>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
