import { apim } from "@/constants/api"
import {
  PLP_END_POINT_CLIENT,
  PROFILE_END_POINT_CLIENT,
  searchPlpEndPoint,
} from "@/constants/index"
import { getConfig } from "@/constants/config"
import { formatListingData } from "@/utils/product"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { HYDRATE } from "next-redux-wrapper"
import { getSessionIdObjWithEpoc } from "@/utils/ApiUtil"

export const fetchSearchInspirationResults = createAsyncThunk(
  "search/fetchSearchResults",
  async general => {
    const config = await getConfig()
    const language = config?.internationalization?.language ?? "en"
    const { general: { lwAppName = "" } = {} } = config ?? {}
    const response = await apim.get(searchPlpEndPoint, {
      params: {
        fl: "id",
        fq: [`language_s:("${language}")`],
        q: "*:*",
        rows: 5,
        profilename: `profile_${lwAppName.toLowerCase()}_Search`,
        collections: lwAppName.toLowerCase(),
        sessionId: getSessionIdObjWithEpoc(),
      },
    })
    return response.data
  }
)

export const getSearchProducts = createAsyncThunk(
  "search/getProducts",
  async payload => {
    const { profilename, collections, q, fq, rows, start, fl } = payload
    const config = await getConfig()
    const language = config?.internationalization?.language ?? "en"
    const url = `${searchPlpEndPoint}?q=${q}&fl=${fl}${
      fq ? `&fq=${fq.join("&fq=")}` : ""
    }${rows ? "&rows=" : ""}${rows ? rows : ""}${start >= 0 ? "&start=" : ""}${
      start >= 0 ? start : ""
    }&profilename=${profilename}&collections=${collections}&fq=language_s:("${language}")&sessionId=${getSessionIdObjWithEpoc()}`
    const response = await apim.get(url)
    return { response }
  }
)

const initialState = {
  searchDetails: {},
  search: { status: "loading", error: "" },
  servicePartsSearch: { status: "loading", error: "" },
  searchResponse: { data: "" },
  servicePartsSearchResponse: { data: "" },
}

export const fetchSearchAllResults = createAsyncThunk(
  "search/fetchSearchAllResults",
  async params => {
    const config = await getConfig()
    const language = config?.internationalization?.language ?? "en"
    if (!params.fq) {
      params.fq = `language_s:("${language}")`
    } else {
      if (typeof params.fq === "string" && params.fq.length > 0) {
        params.fq = [params.fq, `language_s:("${language}")`]
      } else if (Array.isArray(params.fq)) {
        params.fq = [...params.fq, `language_s:("${language}")`]
      }
    }
    const canadaPrice = config?.apiEndPointData?.map?.canadianPrice ?? null
    canadaPrice ? (params.CanadianPrice = canadaPrice) : null
    const searchParams = new URLSearchParams(params)
    const url = `${PLP_END_POINT_CLIENT}?${searchParams.toString()}`
    const response = await apim.get(url)

    return response.data
  }
)
export const fetchSearchServicePartsResults = createAsyncThunk(
  "search/fetchSearchServicePartsResults",
  async params => {
    const config = await getConfig()
    const language = config?.internationalization?.language ?? "en"
    if (!params.fq) {
      params.fq = `language_s:("${language}")`
    } else {
      if (typeof params.fq === "string" && params.fq.length > 0) {
        params.fq = [params.fq, `language_s:("${language}")`]
      } else if (Array.isArray(params.fq)) {
        params.fq = [...params.fq, `language_s:("${language}")`]
      }
    }
    const canadaPrice = config?.apiEndPointData?.map?.canadianPrice ?? null
    canadaPrice ? (params.CanadianPrice = canadaPrice) : null
    const searchParams = new URLSearchParams({
      ...params,
      sessionId: getSessionIdObjWithEpoc(),
    })
    const url = `${PLP_END_POINT_CLIENT}?${searchParams.toString()}`
    const response = await apim.get(url)

    return response.data
  }
)

export const getProducts = createAsyncThunk(
  "plp/getProducts",
  async payload => {
    const { q, profileName, update, query, collections, displaytype, type } =
      payload
    const {
      internationalization: { language = "en" } = {},
      apiEndPointData: { map: { canadianPrice = "" } = {} } = {},
    } = await getConfig()
    const { fq, rows, start, sort } = query
    const url = `${
      type === "parts" ? PROFILE_END_POINT_CLIENT : PLP_END_POINT_CLIENT
    }?profilename=${profileName}${fq ? `&fq=${fq.join("&fq=")}` : ""}${
      sort ? `&sort=${sort}` : ""
    }${q ? `&q=${q}` : ""}${collections ? `&collections=${collections}` : ""}${
      displaytype ? `&displaytype=${displaytype}` : ""
    }${rows ? "&rows=" : ""}${rows ? rows : ""}${start >= 0 ? "&start=" : ""}${
      start >= 0 ? start : ""
    }${`&fq=language_s:("${language}")&sessionId=${getSessionIdObjWithEpoc()}`}${
      canadianPrice ? `&CanadianPrice=${canadianPrice}` : null
    }`

    const response = await apim.get(url)
    const data = await formatListingData(response.data)
    return { data, update }
  }
)

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearchCount: (state, action) => {
      state.searchDetails = action.payload
    },
    setSearchFilter: (state, { payload: { key = "", queries = [] } }) => {
      const searchDetails = {
        ...state.searchDetails,
      }
      if (key)
        searchDetails[key] = {
          ...searchDetails[key],
          filters: queries,
        }
      state.searchDetails = {
        ...state.searchDetails,
      }
      state.searchDetails = searchDetails
    },
    setSearchKeyword: (state, action) => {
      state.searchDetails = {
        ...state.searchDetails,
        keyword: action.payload,
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase([HYDRATE], (state, action) => {
        return {
          ...state,
          ...action.payload,
        }
      })
      .addCase(fetchSearchAllResults.pending, (state, action) => {
        state.search.status = "loading"
      })
      .addCase(fetchSearchAllResults.fulfilled, (state, action) => {
        state.search.status = "succeeded"
        state.search.error = ""
        state.searchResponse.data = action.payload
      })
      .addCase(fetchSearchAllResults.rejected, (state, action) => {
        state.search.status = "failed"
        state.search.error = action.error.message
      })
      .addCase(fetchSearchServicePartsResults.pending, (state, action) => {
        state.servicePartsSearch.status = "loading"
      })
      .addCase(fetchSearchServicePartsResults.fulfilled, (state, action) => {
        state.servicePartsSearch.status = "succeeded"
        state.servicePartsSearch.error = ""
        state.servicePartsSearchResponse.data = action.payload
      })
      .addCase(fetchSearchServicePartsResults.rejected, (state, action) => {
        state.servicePartsSearch.status = "failed"
        state.servicePartsSearch.error = action.error.message
      })
      .addCase(getSearchProducts.pending, (state, action) => {
        state.searchDetails.status = "loading"
      })
      .addCase(getSearchProducts.fulfilled, (state, action) => {
        state.searchDetails = action.payload
        state.searchDetails.status = "succeeded"
      })
      .addCase(getSearchProducts.rejected, (state, action) => {
        state.searchDetails.status = "failed"
        state.searchDetails.error = action.error.message
      })
  },
})

export const { setSearchCount, setSearchFilter, setSearchKeyword } =
  searchSlice.actions
export const searchState = state => state.search
export default searchSlice.reducer
