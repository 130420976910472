import { sanitizeTextForAnalytics } from "@/utils/helper"

import _isEmpty from "lodash/isEmpty"

export const getEventInfo = (
  type,
  needsJSON = false,
  toolTipText,
  accordianState
) => {
  let eventAction
  let eventName
  let internalLinkType
  let internalLinkName
  if (type === "list info mouseover") {
    internalLinkType = "pdp:mouseover"
    internalLinkName = "list"
  } else if (type === "print") {
    internalLinkType = "pdp:print"
  } else if (type === "color selection") {
    internalLinkType = "pdp:color selection"
    internalLinkName = type
  } else if (type === "select configurations") {
    internalLinkName = accordianState
  } else if (type.includes("mouseover") && toolTipText) {
    internalLinkName = toolTipText?.toLowerCase()
    const title = type.replace("mouseover", "")
    internalLinkType = `pdp:mouseover:${title}`
  } else if (type === "remove") {
    internalLinkType = `pdp:${type}`
  } else if (type === "read more") {
    internalLinkType = `pdp:${type}`
  } else if (type === "tooltip") {
    internalLinkName = `info tooltip`
    internalLinkType = eventName = eventAction = `pdp:info tooltip`
  } else if (
    type === "items required for install test" ||
    "edit items required for install"
  ) {
    internalLinkType = eventName = eventAction = `pdp:${type}`
  }

  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}

  const pageInfoDetails = window?.adobeDataLayer?.getState("page")
  const { productInfo } = page
  let internalLinkPositionName = "pdp"
  if (
    pageInfoDetails?.pageInfo?.contentType &&
    pageInfoDetails?.pageInfo?.contentType === "pdp" &&
    !_isEmpty(productInfo)
  ) {
    if (productInfo.pdpType === "bundle") {
      internalLinkPositionName = "pdp:bundle"
    }
  }

  const eventInfo = {
    eventAction:
      eventAction ||
      "pdp:product details:" +
        type +
        (accordianState ? `:${accordianState}` : ""),
    eventName:
      eventName ||
      "pdp:product details:" +
        type +
        (accordianState ? `:${accordianState}` : ""),
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: internalLinkName || type,
    internalLinkPosition: internalLinkPositionName,
    internalLinkType: internalLinkType || "pdp:navigation",
    internalLinkZoneName: "pdp:product details",
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  }

  if (needsJSON) {
    return eventInfo
  }
  return JSON.stringify(eventInfo)
}

export const dataLayerAddToCart = ({ res, productInfo }, serviceName) => {
  const { status, message = "n/a" } = res
  const isStatus = status === 200

  const eventInfo = {
    eventAction: "pdp:cross-sell:kohler professional services:add to cart",
    eventName: "pdp:cross-sell:kohler professional services:add to cart",
    eventType: "navigation",
    eventMsg: message?.toLowerCase(),
    eventStatus: isStatus ? "success" : "failure",
    internalLinkName: "add to cart",
    internalLinkPosition: "pdp",
    internalLinkType: `pdp:${serviceName?.toLowerCase()}`,
    internalLinkZoneName: "pdp:cross-sell:kohler professional services",
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  }
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}

  page.cart = {
    cartID: res?.data?.id,
  }
  window.adobeDataLayer.push({
    event: "cmp:click",
    eventInfo,
    productInfo,
    page,
  })
}

export const addDataLayerForConfigurationExapandState = (
  title,
  accordianState,
  productInfo
) => {
  const eventInfo = getEventInfo(
    "select configurations",
    true,
    null,
    accordianState
  )
  eventInfo.internalLinkType = `pdp:${title}`
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  window.adobeDataLayer.push({
    event: "cmp:click",
    eventInfo,
    page,
    productInfo,
  })
}

export const getBreadcrumbEventInfo = (internalLinkName = "", link = "") => {
  return {
    eventAction: "pdp:breadcrumb",
    eventName: "pdp:breadcrumb",
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: sanitizeTextForAnalytics(internalLinkName),
    internalLinkPosition: "pdp",
    internalLinkType: "pdp:breadcrumb",
    internalLinkZoneName: "pdp:product details",
    internalLinkURL: link
      ? (String(link)?.indexOf("http") === -1 ? window.location.origin : "") +
        link
      : "n/a",
    clickInternalLinks: "true",
  }
}

export const getWidgetEventInfo = (retailer, retailBtnName) => ({
  clickInternalLinks: "false",
  eventAction: `pdp:product details:${retailBtnName}`,
  eventMsg: "n/a",
  eventName: `pdp:product details:${retailBtnName}`,
  eventStatus: "n/a",
  eventType: "navigation",
  internalLinkName: `${retailer}-${retailBtnName}`,
  internalLinkPosition: "pdp",
  internalLinkType: `pdp:${retailBtnName}`,
  internalLinkURL: "n/a",
  internalLinkZoneName: "pdp:product details",
})

export const addBuyBtnAnalytics = (e, retailer) => {
  e.preventDefault()
  e.stopPropagation()
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const retailBtnName = e?.target?.innerText
  const eventInfo = getWidgetEventInfo(retailer, retailBtnName.toLowerCase())
  window.adobeDataLayer.push({
    event: "cmp:click",
    page,
    eventInfo,
  })
}

export const addDataLayerSuperSkuOptionSelected = (
  action,
  field,
  productInfo
) => {
  const url = new URL(window.location.href)
  const formatString = str => {
    str = str.toLowerCase()
    str = str.replace(/_/g, " ")
    return str
  }
  const formatField = formatString(field)
  const formatAction = formatString(action)

  const eventInfo = {
    eventAction: `pdp:${formatField}:${formatAction}`,
    eventName: `pdp:${formatField}:${formatAction}`,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: `${formatAction}:applied`,
    internalLinkPosition: "pdp",
    internalLinkType: `pdp:product options:${formatField}`,
    internalLinkZoneName: "pdp:product details",
    internalLinkURL: `${url}`,
    clickInternalLinks: "true",
  }
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}

  window.adobeDataLayer.push({
    event: "cmp:click",
    eventInfo,
    productInfo,
    page,
  })
}

export const addShareBtnAnalytics = stringProductInfo => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: "pdp:product details:share",
    eventMsg: "n/a",
    eventName: "pdp:product details:share",
    eventStatus: "n/a",
    eventType: "navigation",
    internalLinkName: "share",
    internalLinkPosition: "pdp",
    internalLinkType: "pdp:share",
    internalLinkURL: "n/a",
    internalLinkZoneName: "pdp:product details",
  }
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const { productInfo = "" } = JSON.parse(stringProductInfo)
  window.adobeDataLayer.push({
    event: "cmp:click",
    eventInfo,
    productInfo,
    page,
  })
}
