// import { analyticsCB } from "@/components/cart/cartAnalytics"

export const removeFromCart = (data = {}) => {
  /**
   * It removeFrom cart for pay load
   */
  const {
    lineItemId = "",
    quantity = 0,
    isBundle = false,
    isBundleParent = false,
    sku = "",
  } = data

  const bundlePayload = {
    action: "removeBundleItem",
    customLineItemId: lineItemId,
    sku,
  }

  const actions = [
    isBundleParent
      ? bundlePayload
      : {
          action: isBundle ? "removebundle" : "removeLineItem",
          lineItemId,
          quantity,
        },
  ]
  return actions
}

export const setRemoveCartPayload = (items = []) => {
  /**
   * It removeFrom cart for payload removeBundleItem
   */
  const actions = []
  const allIds = items.filter(item => item.id !== "").map(item => item.id)
  items.forEach(item => {
    const filteredIds = allIds.filter(i => i !== item.id)
    // filtering out the combo sku items, to select only parent item if available
    if (
      !filteredIds.includes(item.bundleParentId) &&
      !item.parentBundleSku &&
      !item.isBundleParent
    ) {
      actions.push({
        action:
          item.isComboSku || item?.bundleParentId
            ? "removebundle"
            : "removeLineItem",
        lineItemId: item?.bundleParentId ? item.bundleParentId : item.id,
        quantity: item.quantity,
      })
    } else if (!item?.parentBundleSku && item?.isBundleParent) {
      // else block added for the static bundles only, as we have different payload
      actions.push({
        action: item?.isBundleParent ? "removeBundleItem" : "removeLineItem",
        customLineItemId: item.id,
        sku: item.sku,
      })
    }
  })
  return actions
}
