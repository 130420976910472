import { isMobile } from "react-device-detect"

import { aemAxiosInstance } from "@/constants/api"
import {
  apimSubscriptionKey,
  apiBaseUrl,
  authorBaseUrl,
  PDP_END_POINT_SUBSCRIPTION_KEY,
  PLP_END_POINT,
  PLP_END_POINT_SUBSCRIPTION_KEY,
  EXCEPTIONAL_SITENAME,
} from "@/constants"
import { getUserPersona } from "@/utils/helper"
import { getProductsBySku } from "@/components/sharedCart/shareCartHelper"
import {
  addCompareProducts,
  clearProduct,
  updateCompareStatus,
} from "@/store/features/compareSlice"
import { store } from "@/store"

const isServer = typeof window === "undefined"

const addCollectionsToEndPointIfNeeded = (url = "", brandName = "") => {
  let endPoint = url
  if (brandName?.toLowerCase() !== EXCEPTIONAL_SITENAME)
    endPoint = `${url}&collections=${brandName?.toLowerCase()}`
  return endPoint
}

const getListFromServer = async (profileName, persona, brandName = "") => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = addCollectionsToEndPointIfNeeded(
        `${apiBaseUrl}${PLP_END_POINT_SUBSCRIPTION_KEY}?profilename=${profileName}&persona=${persona}`,
        brandName
      )
      aemAxiosInstance.defaults.headers.common[
        "Ocp-Apim-Subscription-Key"
      ] = `${apimSubscriptionKey}`
      const { data: content } = await aemAxiosInstance({
        url,
      })
      resolve(content)
    } catch (error) {
      reject(error)
    }
  })
}

const getListFromClient = async (
  profileName,
  persona,
  accessToken,
  brandName = ""
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = addCollectionsToEndPointIfNeeded(
        `${authorBaseUrl}${PLP_END_POINT}?profilename=${profileName}&persona=${persona}`,
        brandName
      )
      aemAxiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${accessToken}`
      const { data: content } = await aemAxiosInstance({
        url,
      })
      resolve(content)
    } catch (error) {
      reject(error)
    }
  })
}

const getPlpParams = (obj, targetKey) => {
  let result = null
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (key === targetKey) {
        return obj[key]
      }

      if (typeof obj[key] === "object" && obj[key] !== null) {
        result = getPlpParams(obj[key], targetKey)

        if (result !== null) {
          return result
        }
      }
    }
  }
  return result
}

const getSearchItems = async (
  searchKey,
  accessToken,
  displayName = "SEARCH"
) => {
  return new Promise(async (resolve, reject) => {
    try {
      aemAxiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${accessToken}`
      const { data: content } = await aemAxiosInstance({
        url: `${authorBaseUrl}${PLP_END_POINT}?displayname=${displayName}&q=${searchKey}`,
      })
      resolve(content)
    } catch (error) {
      reject(error)
    }
  })
}

const formatListingData = async (data, stripResponse) => {
  return new Promise((resolve, reject) => {
    try {
      const persona = getUserPersona()
      const payload = {
        facets: data.facet_counts?.facet_fields,
        facetLabels: data.fusion?.facet_labels ?? [],
        grouped:
          data.fusion?.isCollapsePLP === "true" ||
          data.fusion?.isCollapsePLP === true,
        fusion: data.fusion,
      }

      const priceRange = payload.grouped
        ? data.facet_counts?.facet_ranges[
            `discountedPriceStartingAt.${persona}_d`
          ]
        : data.facet_counts?.facet_ranges[`priceList.${persona}.finalPrice_d`]
      if (priceRange) {
        const gap = priceRange.gap
        // eslint-disable-next-line camelcase
        payload.facets.Price_Range = priceRange.counts.map(item =>
          typeof item === "string"
            ? `[${parseInt(item)} TO ${parseInt(item) + gap}]`
            : item
        )
      }

      // mark multiselect
      payload.facets = formatMultiSelectFacets(data)
      // set color swatches
      payload.colors = data.fusion?.color_swatch_labels ?? {}

      if (data.response) {
        if (stripResponse && data.response.docs.length > 3) {
          payload.data = data.response.docs.slice(0, 3)
        } else {
          payload.data = data.response.docs
        }

        payload.total = data.response.numFound
        payload.start = data.response.start
        if (
          data.fusion?.isCollapsePLP === "true" ||
          data.fusion?.isCollapsePLP === true
        ) {
          payload.grouped = true
        }
      } else {
        payload.data = data.grouped[Object.keys(data.grouped)[0]].groups.map(
          doc => doc.doclist.docs[0]
        )
        payload.total = data.grouped[Object.keys(data.grouped)[0]].groups.length
        payload.start = 0
        payload.grouped = true
      }
      resolve(payload)
      // update
      //   ? dispatch({ type: GET_MORE_PRODUCTS, payload: data.response.docs })
      //   : dispatch({ type: GET_PRODUCTS, payload })

      // dispatch({ type: STOP_LOADING, payload: "plp" })
    } catch (error) {
      reject(error)
    }
  })
}

const formatMultiSelectFacets = data => {
  const facets = Object.entries(data.facet_counts?.facet_fields ?? {}).map(
    fields => ({ [fields[0]]: fields[1] })
  )
  let facetField = data?.fusion["facet.field"]
  if (facetField) {
    if (typeof facetField === "string") facetField = [facetField]
    facetField.forEach(item => {
      facets.forEach((filter, index) => {
        if (filter[item]) {
          const obj = {
            [`**${item}`]: filter[item],
          }
          facets.splice(index, 1, obj)
        }
      })
    })
  }
  const result = Object.assign({}, ...facets)
  return result
}

const getRowsInitialValue = () => {
  let rowsInitialValue = 30
  if (!isServer) {
    rowsInitialValue = isMobile
      ? 30
      : sessionStorage.rows &&
        JSON.parse(sessionStorage.rows)[window.location.pathname]
      ? JSON.parse(sessionStorage.rows)[window.location.pathname]
      : 30
  }
  return rowsInitialValue
}

const addToCompareProduct = (sku, isBackOrder) => {
  store.dispatch(updateCompareStatus(true))
  getProductsBySku([sku])
    .then(async resp => {
      resp.forEach(vsku => {
        store.dispatch(
          addCompareProducts({
            sku: vsku,
            backorder: isBackOrder,
            compareSku: sku,
          })
        )
      })
    })
    .catch(error => {
      store.dispatch(updateCompareStatus(false))
    })
}

const removeFromCompareProduct = sku => {
  store.dispatch(clearProduct(sku))
}

const getProductDetailsFromServer = async (
  profileName,
  collections,
  slug,
  locale
) => {
  return new Promise(async (resolve, reject) => {
    try {
      aemAxiosInstance.defaults.headers.common[
        "Ocp-Apim-Subscription-Key"
      ] = `${apimSubscriptionKey}`
      const response = await aemAxiosInstance({
        url: `${apiBaseUrl}${PDP_END_POINT_SUBSCRIPTION_KEY}?profilename=${profileName}&collections=${collections}&slug=${slug}&fq=language_s:"${locale}"`,
      })
      const { data: { response: { docs = [] } = {} } = {} } = response
      const { data: { fusion: { masterVariantDataResponse = [] } = {} } = {} } =
        response
      resolve(docs[0] ? { ...docs[0], ...{ masterVariantDataResponse } } : {})
    } catch (error) {
      reject(error)
    }
  })
}

export {
  getListFromServer,
  getListFromClient,
  getPlpParams,
  getSearchItems,
  formatListingData,
  getRowsInitialValue,
  addToCompareProduct,
  removeFromCompareProduct,
  getProductDetailsFromServer,
}
