import _get from "lodash/get"
import qs from "qs"
import { HYDRATE } from "next-redux-wrapper"
import { apim } from "@/constants/api"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  FETCH_PRODUCT_REG_ENDPOINT,
  MY_PRODUCTS_ENDPOINT,
  MY_PRODUCT_PRODUCT_REGISTRATION,
  MY_PRODUCT_SEARCH_CATALOG,
} from "@/constants/index"
import { getConfig } from "@/constants/config"
import { handleModelNumberSuggestion } from "@/utils/helper"
import { getSessionIdObjWithEpoc } from "@/utils/ApiUtil"

const initialState = {
  status: "",
  offlineRegistration: {
    suggestions: [],
    invalidModelNumber: false,
    submitError: false,
    isSuccess: false,
    isDisabled: false,
  },

  onlineRegistration: {
    submitError: false,
    isSuccess: false,
    isDisabled: false,
  },
}

export const getModelNumberSuggest = createAsyncThunk(
  "myProduct/getSuggestion",
  async payload => {
    const { q, rows, fq, fl, start, skuSearch } = payload
    const response = await apim.get(MY_PRODUCT_SEARCH_CATALOG, {
      params: {
        q,
        rows,
        fq,
        fl,
        start,
        skuSearch,
        sessionId: getSessionIdObjWithEpoc(),
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: "repeat", encode: false })
      },
    })
    const skuData = handleModelNumberSuggestion(response.data)
    return skuData
  }
)

export const getProductsForRegistration = createAsyncThunk(
  "myProduct/fetchProducts",
  async body => {
    const productsData = []
    const config = await getConfig()
    const locale = config?.internationalization?.locale ?? "en-US"
    await Promise.all([
      apim.get(`${MY_PRODUCTS_ENDPOINT}?lang=${locale}`),
      apim.get(`${FETCH_PRODUCT_REG_ENDPOINT}`),
    ])
      .then(async res => {
        if (res[0]?.data) productsData[0] = res[0]?.data
        if (res[1]?.data) productsData[1] = res[1]?.data
      })
      .catch(err => {
        console.error(
          "Error occured while fetching registered product data",
          err
        )
      })
    return productsData
  }
)

export const offlineProductSubmitRegistration = createAsyncThunk(
  "myProduct/offlineRegistration",
  async body => {
    let message = false
    const { data = {} } = await apim.post(MY_PRODUCT_PRODUCT_REGISTRATION, body)

    if (data) {
      message = _get(data, "success", "") === "true" ? true : false
    }
    return message
  }
)

export const onlineProductSubmitRegistration = createAsyncThunk(
  "myProduct/onlineRegistration",
  async body => {
    let message = false
    const { data = {} } = await apim.post(MY_PRODUCT_PRODUCT_REGISTRATION, body)

    if (data) {
      message = _get(data, "success", "") === "true" ? true : false
    }
    return message
  }
)

export const myProductSlice = createSlice({
  name: "myProduct",
  initialState,
  reducers: {
    clearSuggestion: (state, action) => {
      state.offlineRegistration = {
        ...state.offlineRegistration,
        suggestions: [],
      }
    },
    setInvalidModelNumber: (state, action) => {
      state.offlineRegistration = {
        ...state.offlineRegistration,
        invalidModelNumber: action.payload,
      }
    },
    setSubmitStatus: (state, action) => {
      const { submitError = "", isDisabled = false } = action.payload
      state.offlineRegistration = {
        ...state.offlineRegistration,
        submitError: submitError,
        isDisabled: isDisabled,
      }
    },
    clearSuccess: (state, action) => {
      state.offlineRegistration = {
        ...state.offlineRegistration,
        isSuccess: false,
      }
    },

    setOnlineSubmitStatus: (state, action) => {
      const { submitError = "", isDisabled = false } = action.payload
      state.onlineRegistration = {
        ...state.onlineRegistration,
        submitError: submitError,
        isDisabled: isDisabled,
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase([HYDRATE], (state, action) => {
        return {
          ...state,
          ...action.payload,
        }
      })
      .addCase(getModelNumberSuggest.pending, (state, action) => {
        // state.status = "loading"
      })
      .addCase(getModelNumberSuggest.fulfilled, (state, action) => {
        state.status = "succeeded"

        state.offlineRegistration = {
          ...state.offlineRegistration,
          ...action.payload,
        }
      })
      .addCase(getModelNumberSuggest.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(offlineProductSubmitRegistration.pending, (state, action) => {
        state.offlineRegistration = {
          ...state.offlineRegistration,
          submitError: false,
          isDisabled: true,
        }
        state.status = "loading"
      })
      .addCase(offlineProductSubmitRegistration.fulfilled, (state, action) => {
        state.status = "succeeded"
        const isSuccess = action.payload
        if (isSuccess) {
          state.offlineRegistration = {
            ...state.offlineRegistration,
            isSuccess: true,
            isDisabled: false,
          }
        }
      })
      .addCase(offlineProductSubmitRegistration.rejected, (state, action) => {
        state.offlineRegistration = {
          ...state.offlineRegistration,
          submitError: action.error.message,
          isDisabled: false,
        }
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(getProductsForRegistration.pending, state => {
        state.status = "loading"
      })
      .addCase(getProductsForRegistration.fulfilled, (state, action) => {
        state.status = "succeeded"
      })
      .addCase(getProductsForRegistration.rejected, (state, action) => {
        state.status = "failed"
      })
      .addCase(onlineProductSubmitRegistration.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(onlineProductSubmitRegistration.fulfilled, (state, action) => {
        state.status = "succeeded"
      })
      .addCase(onlineProductSubmitRegistration.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
  },
})

export const {
  clearSuggestion,
  setInvalidModelNumber,
  setSubmitStatus,
  clearSuccess,
  setOnlineSubmitStatus,
} = myProductSlice.actions
export const selectMyAccountState = state => state.myProduct
export default myProductSlice.reducer
