const HttpBackend = require("i18next-http-backend/cjs")
const ChainedBackend = require("i18next-chained-backend").default
const LocalStorageBackend = require("i18next-localstorage-backend").default

module.exports = {
  backend: {
    backendOptions: [
      { expirationTime: 60 * 60 * 1000 },
      {
        // need to revisit this block to load dictionaryPath from global config -  currently not possible due to incompatability with cjs modules. need to redefine imports and exports in future
        loadPath: async function (lng, ns) {
          const dictionaryPath = "/content/kohler.i18nDictionary.json"
          return `/api/locales/${lng}/${ns}.json?path=${dictionaryPath}`
        },
      },
    ],
    backends:
      typeof window !== "undefined" ? [LocalStorageBackend, HttpBackend] : [],
  },
  i18n: {
    locales: ["en", "fr"],
    defaultLocale: "en",
  },
  ns: ["common"],
  serializeConfig: false,
  use: typeof window !== "undefined" ? [ChainedBackend] : [],
}
