import qs from "qs"
import { apim } from "@/constants/api"
import { PLP_END_POINT_CLIENT } from "@/constants/index"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { HYDRATE } from "next-redux-wrapper"
import { formatListingData } from "@/utils/product"

export const laodingStatus = {
  loading: "loading",
  success: "success",
  rejected: "faiure",
}

const shopTheRoomInitialState = {
  roomList: undefined,
  filterList: undefined,
  facets: undefined,
  facetLabels: undefined,
  fusion: {},
  status: laodingStatus.loading,
}

export const shopTheRoomSlice = createSlice({
  name: "shopTheRoomState",
  initialState: shopTheRoomInitialState,
  reducers: {
    setRoomList(state, { payload }) {
      state.roomList = payload.shopTheRoomData?.data ?? []
      state.facets = payload.shopTheRoomData?.facets ?? {}
      state.facetLabels = payload.shopTheRoomData?.facetLabels ?? {}
      state.fusion = payload?.shopTheRoomData?.fusion ?? {}
      state.status = laodingStatus.success
    },
  },
  extraReducers(builder) {
    builder
      .addCase([HYDRATE], (state, action) => {
        return {
          ...state,
          ...action.payload,
        }
      })
      .addCase(getFilterShopRoomList.fulfilled, (state, { payload }) => {
        state.roomList = payload.data ?? state.roomList
        state.facets = payload?.facets ?? state.facets
        state.facetLabels = payload?.facetLabels ?? state.facets
        state.fusion = payload?.fusion ?? state.fusion
        state.status = laodingStatus.success
      })
      .addCase(getFilterShopRoomList.pending, state => {
        state.status = laodingStatus.loading
      })
      .addCase(getFilterShopRoomList.rejected, state => {
        state.status = laodingStatus.rejected
      })
  },
})

const clientShopRoomListFl = [
  "templateName_s",
  "landingFileReference_s",
  "cq_shoptheroomtags_s",
  "productSkuIds_s",
  "modelFileReference_s",
  "cardHoverTitle_s",
  "title_s",
  "cardTitle_s",
  "url_s",
  "strTheme_ss",
  "strColor_ss",
  "strRoom_s",
  "strDesignStyle_s",
]

export const getFilterShopRoomList = createAsyncThunk(
  "shopTheRoomList/getfilterList",
  async payLoad => {
    const { sort = null, fq = [], profilename = "Shop_The_Room" } = payLoad
    const fl = [...clientShopRoomListFl]
    const params = {
      fl: fl.join(),
      collection: "kohler",
      profilename,
      rows: 30,
      ...(fq.length ? { fq } : {}),
      ...(sort ? { sort } : {}),
    }
    const response = await apim.get(PLP_END_POINT_CLIENT, {
      params,
      paramsSerializer: params => {
        return qs.stringify(params, {
          arrayFormat: "repeat",
          encode: false,
        })
      },
    })
    const data = await formatListingData(response.data)
    return data
  }
)

export const { setRoomList } = shopTheRoomSlice.actions
export const shopTheRoomState = state => state.shopTheRoomState
export default shopTheRoomSlice.reducer
