import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { apim } from "@/constants/api"
import {
  FIND_MY_ORDERS_ENDPOINT,
  FIND_MY_ORDERS_V1_ENDPOINT,
  SPLIT_ORDER,
  GET_ORDER_DETAILS_ENDPOINT,
} from "@/constants"
import { store } from "@/store"

const initialState = {
  status: "",
  error: "",
  orders: [],
}

export const findOrderByEmail = createAsyncThunk(
  "orders/email",
  async (payload, thunkAPI) => {
    const body = {
      email: payload.email?.trim().toLowerCase(),
      orderId: payload.orderNo?.trim().toUpperCase(),
    }
    try {
      const state = thunkAPI.getState()
      const { auth: { isAuth = false } = {} } = state
      const isFluent = process.env.NEXT_PUBLIC_FLUENT === "enabled"
      const url = isFluent
        ? FIND_MY_ORDERS_V1_ENDPOINT
        : FIND_MY_ORDERS_ENDPOINT
      if (!isFluent && isAuth) {
        const authData = await store
          .dispatch(getOrder({ orderNo: body.orderId }))
          .unwrap()
        return { results: authData }
      } else {
        const { status, data } = await apim.post(url, body)
        if (status === 200) return data
      }
    } catch (err) {
      return {}
      console.error("Failed to fetch order details", err)
    }
  }
)

export const getOrder = createAsyncThunk("orders/details", async payload => {
  let data = []
  try {
    const res = await apim.get(
      `${GET_ORDER_DETAILS_ENDPOINT}?order-id="${payload.orderNo}"`
    )
    if (res && res.data && res.data.results) {
      data = res.data.results
    }
  } catch (err) {
    // log.error("Failed to fetch order details", err)
    console.error("Failed to fetch order details", err)
  }
  return data
})

export const getOpenOrders = createAsyncThunk("orders/list/open", async () => {
  let data = []
  try {
    const res = await apim.get(`${GET_ORDER_DETAILS_ENDPOINT}?state=open`)
    if (res && res.data && res.data.results) {
      data = res.data.results
    }
  } catch (err) {
    console.error("Failed to fetch open orders", err)
  }
  return data
})

export const getPastOrders = createAsyncThunk("orders/list/past", async () => {
  let data = []
  try {
    const res = await apim.get(`${GET_ORDER_DETAILS_ENDPOINT}?state=past`)
    if (res && res.data && res.data.results) {
      data = res.data.results
    }
  } catch (err) {
    console.error("Failed to fetch past orders", err)
  }
  return data
})

export const getSplitOrder = createAsyncThunk("orderSplit", async payload => {
  const { email = "", orderNo = "" } = payload
  const emailId = email?.trim().toLowerCase()
  const encodeEmail = encodeURIComponent(emailId)
  const response = await apim.get(
    `${SPLIT_ORDER}?parentOrderNumber=${orderNo
      ?.trim()
      .toUpperCase()}&email=${encodeEmail}`
  )
  return response.data
})

export const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(findOrderByEmail.pending, state => {
        state.status = "loading"
      })
      .addCase(findOrderByEmail.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.orders = action.payload.addresses
      })
      .addCase(findOrderByEmail.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(getSplitOrder.pending, state => {
        state.status = "loading"
      })
      .addCase(getSplitOrder.fulfilled, (state, action) => {
        state.status = "succeeded"
      })
      .addCase(getSplitOrder.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(getOrder.pending, state => {
        state.status = "loading"
      })
      .addCase(getOrder.fulfilled, (state, action) => {
        state.status = "succeeded"
      })
      .addCase(getOrder.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
  },
})

export const {} = orderSlice.actions
export const ordersState = state => state.orders
