import { OktaAuth } from "@okta/okta-auth-js"
import {
  isProtectedUrl,
  setWindowProps,
  isAuthenticated,
  addPageLoadAnalyticsEvent,
  getShortenedUrl,
} from "@/utils/helper"

import {
  logout,
  postLogin,
  setAuthModalVisibility,
  setHideCustomerSignupCTA,
  setPersona,
} from "@/store/features/authSlice"
import { store } from "@/store"
import CONS from "@/constants"

let authClient

const initAuth = async config => {
  const {
    apiEndPointData: { map: { oktaScopes } } = {},
    general: {
      homepageUrl = "",
      registerNonKohlerProductPath = "",
      isPublish,
    } = {},
    commerce: { sessionAuth: { securePagePathList = [] } = {} } = {},
    apiEndpoints: {
      oktaClientId = "",
      oktaIssuerUrl = "",
      oktaLogoutUri = "",
    } = {},
  } = config || {}

  authClient = new OktaAuth({
    issuer: oktaIssuerUrl,
    clientId: oktaClientId,
    redirectUri: process.env.NEXT_PUBLIC_GLOBALCONFENV,
    postLogoutRedirectUri: oktaLogoutUri,
    scopes: oktaScopes
      ? oktaScopes.split(",")
      : ["kcom-session", "openid", "offline_access"],
    tokenManager: {
      autoRenew: true,
      expireEarlySeconds: 120,
    },
  })

  authClient.start()

  let prevAuthState = null

  authClient.authStateManager.subscribe(authState => {
    if (authState.isAuthenticated) {
      const payload = {
        accessToken: authState.accessToken.accessToken,
        refreshToken: authState.refreshToken.refreshToken,
        user: authState.accessToken.claims || {},
        isAuth: authState.isAuthenticated,
      }
      store.dispatch(postLogin(payload))
    }
    if (
      prevAuthState &&
      prevAuthState.isAuthenticated !== authState.isAuthenticated
    ) {
      if (document.hidden) window.location.reload()
    }
    prevAuthState = authState

    addPageLoadAnalyticsEvent()
  })

  authClient.tokenManager.on("error", async function (err) {
    console.error(err)
    await authClient.signOut({ clearTokensBeforeRedirect: true })
    await authClient.session.close()
  })

  const isLoggedInRedirect = await authClient.isLoginRedirect()
  if (isLoggedInRedirect) {
    const { tokens } = await authClient.token.parseFromUrl()
    authClient.tokenManager.setTokens(tokens)
    const { accessToken } = tokens
    store.dispatch(postLogin(accessToken.claims))
  } else {
    authClient.authStateManager.updateAuthState()

    try {
      const accessToken = await authClient.tokenManager.get("accessToken")
      const isSessionExists = await authClient.session.exists()
      if (
        accessToken &&
        !authClient.tokenManager.hasExpired(accessToken) &&
        isSessionExists
      ) {
        // check  if force renewal requested in previous session
        const userInfo = sessionStorage.getItem(CONS.KF_UPDATE_MEMBERSHIP)
        console.log("userInfo", userInfo)
        if (localStorage.getItem(CONS.KF_FORCE_RENEW_TOKEN)) {
          if (userInfo) {
            const user = JSON.parse(userInfo)
            if (user.uid === accessToken.claims.uid) {
              const payload = {
                user: accessToken.claims,
              }
              store.dispatch(postLogin(payload))
              store.dispatch(setPersona(user.persona))
            }
          } else {
            const renwedToken = await authClient.tokenManager.renew(
              "accessToken"
            )
            const payload = {
              user: renwedToken.claims,
            }
            store.dispatch(postLogin(payload))
            localStorage.removeItem(CONS.KF_FORCE_RENEW_TOKEN)
          }
        } else {
          const payload = {
            user: accessToken.claims,
          }
          store.dispatch(postLogin(payload))
        }
      } else if (isSessionExists) {
        authClient.token
          .getWithRedirect({
            responseType: ["id_token", "access_token", "code"],
            state: location.href,
          })
          .catch(err => {
            console.error("error in getting token", err)
          })
      } else if (!accessToken) {
        const isProtected = await isProtectedUrl()
        if (isProtected && isPublish) {
          const redirectAction = {
            isLoginAction: true,
            loginCallbackURL: window.location.href,
          }
          localStorage.setItem(
            "SecurePageRedirect",
            JSON.stringify(redirectAction)
          )
          window.location.replace(await getShortenedUrl(homepageUrl))

          if (window.location.pathname === homepageUrl) {
            store.dispatch(setAuthModalVisibility({ show: true }))
          }
        } else if (localStorage.getItem("SecurePageRedirect")) {
          const redirectAction = JSON.parse(
            localStorage.getItem("SecurePageRedirect")
          )

          if (redirectAction.isLoginAction) {
            window.loginCallback = redirectAction.loginCallbackURL
            localStorage.removeItem("SecurePageRedirect")
            store.dispatch(setAuthModalVisibility({ show: true }))
          }
        }
        // store?.dispatch(getOktaToken("guest"))
      } else {
        store.dispatch(logout())
      }
    } catch (err) {
      console.error(
        "Okta error while getting access token from token manager",
        err
      )
    }
  }

  securePagePathList.forEach(path => {
    document.querySelectorAll(`a[href="${path}"]`).forEach(tag => {
      tag.onclick = function (e) {
        e.preventDefault()
        if (registerNonKohlerProductPath === this.href) {
          setWindowProps("signUpConfirmationCTA", "Register Products")
        }
        isAuthenticated(this.href)
      }
    })
  })

  document.querySelectorAll('a[href^="#isAuth"]').forEach(link => {
    link.addEventListener("click", e => {
      e.preventDefault()
      const url = e.target.href.split("::")[1]
      isAuthenticated(url)
    })
  })

  document.querySelectorAll('a[href*="#KPN"]').forEach(link => {
    link.addEventListener("click", e => {
      e.preventDefault()
      const link = e.target.href.split("::")
      const id = link[0]
      const url = link[1]
      if (link) {
        store.dispatch(setHideCustomerSignupCTA(true))
        if (id.includes("signIn")) {
          window.KPNSignIn = true
          isAuthenticated(url, 0, true)
        } else if (id.includes("createAccount")) {
          window.KPNSignIn = false
          isAuthenticated(url, 1, true)
        } else {
          isAuthenticated(url)
        }
      }
    })
  })
}

export const resetPassword = (
  recoveryToken = "",
  newPassword = "",
  cb = () => {},
  errCb = () => {}
) => {
  authClient
    .verifyRecoveryToken({ recoveryToken })
    .then(function (transaction) {
      if (transaction.status === "PASSWORD_RESET") {
        transaction
          .resetPassword({ newPassword })
          .then(() => {
            cb()
          })
          .catch(ex => errCb(ex.message))
      } else {
        errCb()
      }
    })
    .catch(function (ex) {
      errCb(ex.message)
    })
}

export { initAuth, authClient }
