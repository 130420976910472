import _get from "lodash/get"
import DOMPurify from "dompurify"
import CONSTANTS from "@/constants/index"

const sanitizeText = text => text.replace(/<\/?[^>]+(>|$)/g, "")

const checkStock = (backOrder, category) => {
  if (!category) return false
  if (backOrder === true || backOrder === "Yes") return false
  if (category === "Install Services") return false
  if (category === "Design Services") return false
  return true
}

const getQuantityCount = data => {
  const totalCount = data
    ?.filter(item => !item.custom?.fields?.bundleParentId)
    ?.reduce(function (accumulator, item) {
      return accumulator + item.quantity
    }, 0)
  return totalCount
}

export const sanitizeDOMString = property =>
  typeof property === "string" ? DOMPurify.sanitize(property) : property

const getStateCode = (stateVal, countryVal, data) => {
  let country = {}
  if (data.find(obj => obj.code === countryVal)) {
    country = data.find(obj => obj.code === countryVal)
  } else {
    country = data.find(obj => obj.name === countryVal) ?? {}
  }
  if (country.states?.find(obj => obj.code === stateVal)) return stateVal
  const state = country.states?.find(obj => obj.name === stateVal) ?? {}
  return state.code ?? ""
}

const getCountryCode = (countryVal, data) => {
  if (data.find(obj => obj.code === countryVal)) return countryVal
  const country = data.find(obj => obj.name === countryVal) ?? {}
  return country.code ?? ""
}

const getStateName = (stateVal, countryVal, data) => {
  let country = {}
  if (data.find(obj => obj.name === countryVal)) {
    country = data.find(obj => obj.name === countryVal)
  } else {
    country = data.find(obj => obj.code === countryVal) ?? {}
  }
  if (country.states?.find(obj => obj.name === stateVal)) return stateVal
  const state = country.states?.find(obj => obj.code === stateVal) ?? {}
  return state.name ?? ""
}

const getCountryName = (countryVal, data) => {
  if (data.find(obj => obj.name === countryVal)) return countryVal
  const country = data.find(obj => obj.code === countryVal) ?? {}
  return country.name ?? ""
}

const convertToPhoneNumber = text => {
  try {
    if (text) {
      const value = text
      const x = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      return !x[2]
        ? "(" + x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "")
    } else {
      return
    }
  } catch (error) {
    console.error(error)
  }
}

const validateEmail = email => {
  if (CONSTANTS.REGEX_EMAIL.test(email)) return true
  return false
}

const validatePhone = phone => {
  phone = phone.replace("(", "")
  phone = phone.replace(")", "")
  phone = phone.replace(" ", "")
  phone = phone.replace("-", "")
  if (/^\d+$/.test(phone) && phone.length > 9) return true
  return false
}

const getAttributeValue = (attributes, name) =>
  _get(
    attributes.find(attr => attr.name === name),
    "value",
    ""
  )

export {
  checkStock,
  getQuantityCount,
  getAttributeValue,
  sanitizeText,
  getStateCode,
  getCountryCode,
  getStateName,
  getCountryName,
  convertToPhoneNumber,
  validateEmail,
  validatePhone,
}
