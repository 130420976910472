import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  asProductDetails: {
    sku: "",
    slug: "",
    variants: [],
    recommendedAccessories: [],
    productBrand: "",
    data: {},
  },
}

export const asPdpSlice = createSlice({
  name: "asPdp",
  initialState,
  reducers: {},
  extraReducers: () => {
    // Placeholder PLP slice so that it can be overridden for AnnSacks PLP Page.
  },
})

export const { setAsProductDetails } = asPdpSlice.actions
export const selectPdpState = state => state.asPdp

export default asPdpSlice.reducer
