import _get from "lodash/get"

import { apim } from "@/constants/api"
import { getStockEndPoint } from "@/constants/index"
import { store } from "@/store"
import { getConfig } from "@/constants/config"
import { getShortenedUrl } from "@/utils/location"

import {
  setCartUrl,
  deleteCart,
  setCart,
  setCartCount,
  setShipping,
  createCart,
  setNoshipping,
} from "@/store/features/cartSlice"

const getStock = async sku => {
  let stock = 0
  const response = await apim.get(`${getStockEndPoint}/?sku=${sku}`)
  if (
    response &&
    response.data &&
    response.data.results &&
    response.data.results[0]
  ) {
    stock = response.data.results[0].availableQuantity ?? 0
  }
  return stock
}

const getQuantityCount = data => {
  if (!(data && data.length)) return 0
  const totalCount =
    data &&
    data
      .filter(item => !item.custom?.fields?.bundleParentId)
      .reduce((accumulator, item) => {
        return accumulator + item.quantity
      }, 0)
  return totalCount
}

const getCartCount = () => {
  const { cartCount } = store?.getState()?.cart ?? 0
  return cartCount
}

const getAriaLabel = (t, cartCount) => {
  const ariaLabel =
    cartCount > 1
      ? t("kf.minicart.aria.label.cartItems", { count: cartCount })
      : t("kf.minicart.aria.label.cartItem", { count: cartCount })
  return ariaLabel
}

const setCartLandingPagePath = async () => {
  const config = await getConfig()
  const cartLandingUrl = await getShortenedUrl(
    _get(config, "general.cartLandingPagePath", "/")
  )
  store.dispatch(setCartUrl(cartLandingUrl))
}

const getCartLandingPagePath = async () => {
  const config = await getConfig()
  const cartLandingUrl = await getShortenedUrl(
    _get(config, "general.cartLandingPagePath", "/")
  )
  return cartLandingUrl
}
const getCartPagePath = () => {
  let path = "/"
  path = getCartLandingPagePath().then(res => {
    return res
  })
  return path
}

export const getCustomerCartId = () => {
  return localStorage.getItem("customerCartId")
}
export const setCustomerCartId = item => {
  localStorage.setItem("customerCartId", item)
}
export const removeCustomerCartId = () => {
  localStorage.removeItem("customerCartId")
}

export const getCartIdParams = isSubmit => {
  const {
    auth: { user: { koc: isIscUser } = {} } = {},
    cart: { cart: { id = "" } = {} } = {},
  } = store.getState()
  const customerCartId = getCustomerCartId()
  const cartId = isIscUser && customerCartId ? `&cartId=${customerCartId}` : ""
  const cartIdQuery =
    isIscUser && customerCartId
      ? `?cartId=${customerCartId}`
      : isSubmit
      ? `?cartId=${id}`
      : ""
  const activeCartId = isIscUser && customerCartId ? customerCartId : id

  return { cartId, cartIdQuery, activeCartId }
}

export const deleteFromCart = async cb => {
  await store
    .dispatch(deleteCart())
    .unwrap()
    .then(res => {
      if (res) {
        cb && cb("success", null, res ? res : {})
        store.dispatch(setCart({ lineItems: [] }))
        store.dispatch(setNoshipping([]))
        store.dispatch(setCartCount(0))
        store.dispatch(setShipping([]))
        const cartPayload = {
          currency: "USD",
          taxMode: "ExternalAmount",
        }
        store.dispatch(createCart(cartPayload))
      }
    })
    .catch(() => {
      cb && cb("failure", "failed to empty your cart.")
    })
}

export {
  getStock,
  getQuantityCount,
  getCartCount,
  getAriaLabel,
  setCartLandingPagePath,
  getCartPagePath,
  getCartLandingPagePath,
}
