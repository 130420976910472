const Layout = ({ children, ...props }) => {
  return (
    <>
      <main>
        {props.show && <div className={"modal-backdrop fade show"}></div>}
        {children}
      </main>
    </>
  )
}

export default Layout
