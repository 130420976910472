import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

const initialState = {
  inspirations: {
    selectedInspiration: {},
    loading: true,
    fq: [],
    data: [],
    total: 0,
    facets: [],
    start: 0,
    colors: {},
    grouped: false,
  },
}
export const getInspirationDetails = createAsyncThunk(
  "inspirations/getInspirationDetails",
  async () => {
    // Placeholder Inspiration slice So That It Can Be Overridden For AnnSacks Inspiration Page.
  }
)
export const inspirationSlice = createSlice({
  name: "inspirations",
  initialState,
  reducers: {},
  extraReducers: () => {
    // Placeholder Inspiration slice So That It Can Be Overridden For AnnSacks Inspiration Page.
  },
})
export default inspirationSlice.reducer
