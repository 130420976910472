import { createSlice } from "@reduxjs/toolkit"
import _get from "lodash/get"

const initialState = {
  category: "",
  comparedProducts: [],
  expiresIn: 60,
  isEnable: false,
}

export const compareSlice = createSlice({
  name: "compare",
  initialState,
  reducers: {
    clearProduct(state, action) {
      state.comparedProducts.forEach(obj => {})
      const products = state.comparedProducts.filter(
        product => product.compareSku !== action.payload
      )
      const query = products.map(item => item.compareSku).join(",")
      const path = `${
        products.length > 0 ? "?compare=" : ""
      }${query.toString()}`
      if (!query) {
        window.history.replaceState(null, "", window.location.pathname)
      } else {
        window.history.replaceState(
          window.location.pathname,
          document.title,
          path
        )
      }

      state.category = products.length === 0 ? "" : state.category
      state.comparedProducts = products
    },

    setCompareExpiry(state, action) {
      state.expiresIn = action.payload
    },

    clearAllCompareProducts(state, action) {
      state.category = ""
      state.comparedProducts = []
    },

    addCompareProducts(state, action) {
      try {
        const { expiresIn } = state
        const { sku = {}, backorder = "No", compareSku = "" } = action.payload
        const product = sku
        if (product && product != {}) {
          product.compareSku = compareSku || sku.masterVariant?.sku
          product.expiry = Date.now() + expiresIn * 24 * 3600 * 1000
          if (backorder === "Yes") product.backorder = true
          const productsTemp = state.comparedProducts
          const prodMain = productsTemp.filter(obj => {
            return obj.compareSku !== sku.masterVariant?.sku
          })
          prodMain.push(product)
          state.comparedProducts = prodMain
          state.category = _get(
            product.masterVariant?.attributes?.find(
              attr => attr.name === "ProductLocalCategory"
            ),
            "value",
            ""
          )
        }
        state.isEnable = false
      } catch (error) {
        state.isEnable = false
      }
    },

    updateCompareStatus(state, action) {
      state.isEnable = action.payload
    },
  },
})

export const {
  setCompareExpiry,
  clearAllCompareProducts,
  addCompareProducts,
  clearProduct,
  updateCompareStatus,
} = compareSlice.actions
export const compareState = state => state.compare
