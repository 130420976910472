import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { createWrapper } from "next-redux-wrapper"
import storage from "@/store/syncStorage"
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist"
import { authSlice } from "@/store/features/authSlice"
import { cartSlice } from "@/store/features/cartSlice"
import { locationSlice } from "@/store/features/locationSlice"
import { genericSlice } from "@/store/features/genericSlice"
import { checkoutSlice } from "@/store/features/checkoutSlice"
import { productListSlice } from "@/store/features/productListSlice"
import { orderSlice } from "@/store/features/orderSlice"
import { favoritesSlice } from "@/store/features/favoritesSlice"
import { compareSlice } from "@/store/features/compareSlice"
import createFilter from "redux-persist-transform-filter"
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2"
import { searchSlice } from "@/store/features/searchSlice"
import { pdpSlice } from "@/store/features/pdpSlice"
import { myProductSlice } from "@/store/features/myProductSlice"
import { inspirationSlice } from "@/store/features/inspirationSlice"
import shopTheRoomReducer, {
  shopTheRoomSlice,
} from "./features/shopTheRoomSlice"
import { asPlpSlice } from "@/store/features/asPlpSlice"
import { asPdpSlice } from "@/store/features/asPdpSlice"
const combinedReducer = combineReducers({
  [authSlice.name]: authSlice.reducer,
  [cartSlice.name]: cartSlice.reducer,
  [locationSlice.name]: locationSlice.reducer,
  [genericSlice.name]: genericSlice.reducer,
  [checkoutSlice.name]: checkoutSlice.reducer,
  [productListSlice.name]: productListSlice.reducer,
  [orderSlice.name]: orderSlice.reducer,
  [favoritesSlice.name]: favoritesSlice.reducer,
  [inspirationSlice.name]: inspirationSlice.reducer,
  [compareSlice.name]: compareSlice.reducer,
  [searchSlice.name]: searchSlice.reducer,
  [pdpSlice.name]: pdpSlice.reducer,
  [myProductSlice.name]: myProductSlice.reducer,
  [shopTheRoomSlice.name]: shopTheRoomReducer,
  [asPlpSlice.name]: asPlpSlice.reducer,
  [asPdpSlice.name]: asPdpSlice.reducer,
})

let store
const makeStore = () => {
  const isServer = typeof window === "undefined"
  if (isServer) {
    // If it's on server side, create a store
    return configureStore({
      reducer: combinedReducer,
      middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
          serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
          },
        }),
    })
  } else {
    // If it's on client side, create a store which will persist

    const authSubsetFilter = createFilter("auth", [
      "anonymousId",
      "access_token",
      "refresh_token",
      "expires_in",
      "revalidate_in",
      "hashedEmail",
      "customerProspect",
    ])

    const persistConfig = {
      key: "root",
      whitelist: ["auth", "location", "compare"],
      transforms: [authSubsetFilter],
      storage,
      stateReconciler: autoMergeLevel2,
    }

    const persistedReducer = persistReducer(persistConfig, combinedReducer)

    store = configureStore({
      reducer: persistedReducer,
      middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
          serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
          },
        }),
    })

    store.__persistor = persistStore(store)

    return store
  }
}
export { store, makeStore }
export const wrapper = createWrapper(makeStore)
