import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

const initialState = {
  plp: {
    loaded: false,
    loading: true,
    fq: [],
    data: [],
    total: 0,
    facets: [],
    facetLabels: [],
    start: 0,
    colors: {},
    grouped: false,
  },
}

export const getProducts = createAsyncThunk("asPlp/getProducts", async () => {
  // Placeholder PLP slice so that it can be overridden for AnnSacks PLP Page.
})
export const asPlpSlice = createSlice({
  name: "asPlp",
  initialState,
  reducers: {},
  extraReducers: () => {
    // Placeholder PLP slice so that it can be overridden for AnnSacks PLP Page.
  },
})

export const { startLoading, stopLoading } = asPlpSlice.actions
export const selectPlpState = state => state.asPlp

export default asPlpSlice.reducer
