import qs from "qs"

import { apim } from "@/constants/api"
import {
  HELP_FAQ_END_POINT,
  PLP_END_POINT_CLIENT,
  searchPlpEndPoint,
  searchProfilePlpEndPoint,
} from "@/constants/index"
import { getConfig } from "@/constants/config"
import { getUserPersona } from "@/utils/helper"
import { formatListingData } from "@/utils/product"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { HYDRATE } from "next-redux-wrapper"
import { getSessionIdObjWithEpoc } from "@/utils/ApiUtil"

const initialState = {
  profile_service_parts_Search: {},
  profile_brand_Typeahead: {},
  profile_brand_Search: {},
  profile_brand_Top_Searches: {},
  plp: {
    status: "loading-yes",
    error: "",
    initialLoad: true,
    suggestions: [],
    suggestionExactMatch: false,
  },
}

export const profileBrandTypeahead = createAsyncThunk(
  "profileBrandTypeahead",
  async query => {
    const config = await getConfig()
    const language = config?.internationalization?.language ?? "en"
    const response = await apim.get(
      `${searchPlpEndPoint}?rows=10&fl=sku_s,masterCustomerFacingSKU_s,productName_s&collections=${
        query[1]
      }&profilename=profile_${query[1]}_Typeahead&q=${
        query[0]
      }&start=0&fq=language_s:("${language}")&sessionId=${getSessionIdObjWithEpoc()}`
    )
    return response.data
  }
)

export const profileServicePartsSearch = createAsyncThunk(
  "profileServicePartsSearch",
  async query => {
    const config = await getConfig()
    const language = config?.internationalization?.language ?? "en"
    const response = await apim.get(
      `${searchPlpEndPoint}?rows=${query[2]}&fl=${
        query[1]
      }&collections=service_parts&profilename=profile_service_parts_Search&q=${
        query[0]
      }&fq=language_s%3A("${language}")&sessionId=${getSessionIdObjWithEpoc()}`
    )
    return response.data
  }
)
export const profileBrandSearch = createAsyncThunk(
  "profileBrandSearch",
  async query => {
    const config = await getConfig()
    const language = config?.internationalization?.language ?? "en"
    const response = await apim.get(
      `${searchPlpEndPoint}?rows=${query[3]}&fl=${
        query[2]
      }&lw.tagger.debug=true&collections=${query[1]}&profilename=profile_${
        query[1]
      }_Search&q=${query[0]}&fq=${
        query[4]
      }&fq=language_s:("${language}")&sessionId=${getSessionIdObjWithEpoc()}`
    )
    return response.data
  }
)
export const profileBrandTopSearches = createAsyncThunk(
  "profileBrandTopSearches",
  async brand => {
    const config = await getConfig()
    const language = config?.internationalization?.language ?? "en"
    const response = await apim.get(
      `${searchProfilePlpEndPoint}?q=*.*&profilename=profile_${brand}_Top_Searches&collections=${brand}&fq=language_s:("${language}")&sessionId=${getSessionIdObjWithEpoc()}`
    )
    return response.data
  }
)

export const getProducts = createAsyncThunk(
  "plp/getProducts",
  async payload => {
    const persona = getUserPersona()
    const {
      profileName: profilename,
      update,
      query,
      isFaq,
      isSuggestion,
      collections,
      ...params
    } = payload
    const url = isFaq ? HELP_FAQ_END_POINT : PLP_END_POINT_CLIENT

    const config = await getConfig()
    const language = config?.internationalization?.language ?? "en"
    const canadianPrice = config?.apiEndPointData?.map?.canadianPrice ?? null
    const customParams = {
      ...query,
      ...params,
      persona,
      profilename,
      sessionId: getSessionIdObjWithEpoc(),
    }
    if (collections) customParams.collections = collections
    if (canadianPrice) customParams.CanadianPrice = canadianPrice
    if (!isFaq) {
      if (!customParams.fq) {
        customParams.fq = `language_s:("${language}")`
      } else {
        if (typeof customParams.fq === "string" && customParams.fq.length > 0) {
          customParams.fq = [customParams.fq, `language_s:("${language}")`]
        } else if (Array.isArray(customParams.fq)) {
          customParams.fq = [...customParams.fq, `language_s:("${language}")`]
        }
      }
    }

    const response = await apim.get(url, {
      params: customParams,
      paramsSerializer: params => {
        return qs.stringify(params, {
          arrayFormat: "repeat",
          encode: false,
        })
      },
    })
    const data = isSuggestion
      ? response?.data?.response?.docs
      : await formatListingData(response.data)
    return {
      data,
      update,
      isSuggestion,
      total: response.data?.response["numFound"],
    }
  }
)

export const productListSlice = createSlice({
  name: "productList",
  initialState,
  reducers: {
    setProfileServicePartsSearch(state, action) {
      state.profile_service_parts_Search = action.payload
    },
    setProfileBrandTypeahead(state, action) {
      state.profile_brand_Typeahead = action.payload
    },
    setProfileBrandSearch(state, action) {
      state.profile_brand_Search = action.payload
    },
    setPlpData(state, action) {
      state.plp = action?.payload
      state.plp["status"] = "succeeded"
    },
    updatePlpData(state, action) {
      state.plp = {
        ...state.plp,
        data: [...state.plp.data, ...action.payload],
      }
    },
    updatePlpDataStatus(state, action) {
      state.plp.status = action.payload
    },
    clearSuggestion(state) {
      state.plp.suggestions = []
    },
  },
  extraReducers(builder) {
    builder
      .addCase([HYDRATE], (state, action) => {
        return {
          ...state,
          ...action.payload,
        }
      })
      .addCase(profileBrandSearch.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(profileBrandSearch.fulfilled, (state, action) => {
        state.profile_brand_Search = action.payload
        state.profile_brand_Search.status = "succeeded"
      })
      .addCase(profileBrandSearch.rejected, (state, action) => {
        state.profile_brand_Search.status = "failed"
        state.profile_brand_Search.error = action.error.message
      })
      .addCase(profileServicePartsSearch.pending, (state, action) => {
        state.profile_service_parts_Search.status = "loading"
      })
      .addCase(profileServicePartsSearch.fulfilled, (state, action) => {
        if (action.payload && action.payload !== "") {
          state.profile_service_parts_Search = action.payload
          state.profile_service_parts_Search.status = "succeeded"
        }
      })
      .addCase(profileServicePartsSearch.rejected, (state, action) => {
        state.profile_service_parts_Search.status = "failed"
        state.profile_service_parts_Search.error = action.error.message
      })
      .addCase(profileBrandTopSearches.pending, (state, action) => {
        state.profile_brand_Top_Searches.status = "loading"
      })
      .addCase(profileBrandTopSearches.fulfilled, (state, action) => {
        if (action.payload && action.payload !== "") {
          state.profile_brand_Top_Searches = action.payload
          state.profile_brand_Top_Searches.status = "succeeded"
        }
      })
      .addCase(profileBrandTopSearches.rejected, (state, action) => {
        state.profile_brand_Top_Searches.status = "failed"
        state.profile_brand_Top_Searches.error = action.error.message
      })
      .addCase(profileBrandTypeahead.pending, (state, action) => {
        state.profile_brand_Typeahead.status = "loading"
      })
      .addCase(profileBrandTypeahead.fulfilled, (state, action) => {
        state.profile_brand_Typeahead = action.payload
        state.profile_brand_Typeahead.status = "succeeded"
      })
      .addCase(profileBrandTypeahead.rejected, (state, action) => {
        state.profile_brand_Typeahead.status = "failed"
        state.profile_brand_Top_Searches.error = action.error.message
      })
      .addCase(getProducts.pending, (state, action) => {
        if (!state.plp.initialLoad) {
          state.plp.status = "loading"
        }
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        const { data, update, isSuggestion = "", total = 0 } = action?.payload

        if (isSuggestion) {
          state.plp = {
            ...state.plp,
            suggestions: data,
            suggestionExactMatch: isSuggestion,
          }
        } else if (update) {
          state.plp = {
            ...state.plp,
            data: state.plp.data.concat(data.data),
          }
        } else {
          state.plp = data
        }
        state.plp.initialLoad = false
        state.plp.status = "succeeded"
        state.plp.total = total
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.plp.status = "failed"
        state.plp.error = action.error.message
      })
  },
})

export const {
  setProfileServicePartsSearch,
  setProfileBrandTypeahead,
  setProfileBrandSearch,
  setPlpData,
  updatePlpData,
  updatePlpDataStatus,
  clearSuggestion,
} = productListSlice.actions
export const selectProductListState = state => state.productList
export default productListSlice.reducer
