import { SPECIAL_CHAR_REGEX } from "@/constants/regex"
import { cleanText, sanitizeTextForAnalytics } from "@/utils/helper"

export const addDataLayerFav = (
  msg = "",
  isSuccess = false,
  type = "",
  internalLinkType = "",
  zone = "",
  videoName = "",
  videoId = "",
  imageName = ""
) => {
  const sharedZoneName = zone?.replace(SPECIAL_CHAR_REGEX, " ") ?? ""
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const {
    pageInfo: { contentType },
  } = page
  const isPdp = contentType === "pdp"
  const sharedInternalLinkType = internalLinkType
    ? sanitizeTextForAnalytics(internalLinkType)
    : "teaser"
  const cleanSharedInternalLinkType = cleanText(sharedInternalLinkType)
  const sharedInternalLinkZoneName = sharedZoneName
    ? sanitizeTextForAnalytics(sharedZoneName)
    : "teaser"
  const eventPageType =
    contentType === "locations"
      ? "locations:kohler stores"
      : window.eventPageType
  const pageType = `${eventPageType}:${sharedInternalLinkZoneName}`
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${pageType}:favorite ${type}`,
    eventName: `${pageType}:favorite ${type}`,
    eventMsg: msg || "n/a",
    eventStatus: isSuccess ? "success" : "failure",
    eventType: "navigation",
    internalLinkName: `favorite ${type}`,
    internalLinkPosition: eventPageType,
    internalLinkType: `${eventPageType}:${cleanSharedInternalLinkType}`,
    internalLinkURL: "n/a",
    internalLinkZoneName: `${eventPageType}:${sharedInternalLinkZoneName}`,
  }
  if (videoName) eventInfo.videoName = videoName
  if (videoId) eventInfo.videoId = videoId
  if (imageName) eventInfo.imageName = imageName

  const dataLayerObj = {
    event: "cmp:click",
    eventInfo,
    page,
  }

  if (isPdp) {
    const {
      pageInfo: { pageUrl },
      productInfo,
    } = page
    const isAdd = type === "add" ? true : type === "remove" ? false : true
    const eventInfoPdp = {
      clickInternalLinks: "true",
      eventAction: `pdp:product details:favorite ${type}`,
      eventName: `pdp:product details:favorite ${type}`,
      eventMsg: isAdd
        ? "your item has been saved to your favorites."
        : "item has been removed from your favorites.",
      eventStatus: "success",
      eventType: isAdd ? "wishlist save" : "navigation",
      internalLinkName: `favorite ${type}`,
      internalLinkPosition: `pdp${
        productInfo?.pdpType === "bundle" ? ":bundle" : ""
      }`,
      internalLinkType: "pdp:favorites",
      internalLinkURL: pageUrl || "n/a",
      internalLinkZoneName: `pdp:product details`,
    }
    dataLayerObj["productInfo"] = productInfo
    dataLayerObj["eventInfo"] = eventInfoPdp
  }

  window.adobeDataLayer.push(dataLayerObj)
  delete window.zoneName
  delete window.internalLinkType
}
