import { createSlice } from "@reduxjs/toolkit"
import moment from "moment"

const initialState = {
  userCity: "",
  utilityLocation: "",
  utilityData: {},
  utilityStoreDetails: {},
  myStoreId: 0,
  utilityStoreDetailsUrl: "",
  isLoading: false,
  reCallIn: "",
  browseLocationsStoreFilterData: [],
  findAStoreShow: false,
  findAStoreActive: false,
  findAStoreSearchEvent: false,
}

export const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setAPIRecallTime(state, action) {
      state.reCallIn = moment().add(1, "m").unix()
    },
    setStoreAddress: (state, action) => {
      state.utilityData = action.payload
      state.reCallIn = moment().add(1, "m").unix()
      state.isLoading = false
    },
    setUtilityStoreDetails: (state, action) => {
      state.utilityStoreDetails = action.payload
      state.reCallIn = moment().add(1, "m").unix()
      state.isLoading = false
    },
    setCityLocation: (state, action) => {
      const {
        payload: { userCity = "", utilityLocation = "" },
      } = action

      state.userCity = userCity
      state.utilityLocation = utilityLocation
      state.reCallIn = moment().add(1, "m").unix()
      state.isLoading = false
      localStorage.setItem("currentZipCode", utilityLocation)
    },
    setUtilityLocation: (state, action) => {
      const { payload: { utilityLocation = "" } = {} } = action
      state.utilityLocation = utilityLocation
      localStorage.setItem("currentZipCode", utilityLocation)
    },
    setMyStore: (state, action) => {
      state.myStoreId = action.payload
    },
    setStoreFilter: (state, action) => {
      state.browseLocationsStoreFilterData = action.payload
    },
    setFindAStoreShow: (state, action) => {
      state.findAStoreShow = action.payload
    },
    setFindAStoreActive: (state, action) => {
      state.findAStoreActive = action.payload
    },
    setFindAStoreSearchEvent: (state, action) => {
      state.findAStoreSearchEvent = action.payload
    },
  },
})

export const {
  setStoreAddress,
  setAPIRecallTime,
  setUtilityStoreDetails,
  setCityLocation,
  setMyStore,
  setUtilityLocation,
  setStoreFilter,
  setFindAStoreActive,
  setFindAStoreShow,
  setFindAStoreSearchEvent,
} = locationSlice.actions
export const locationState = state => state.location
